import React from 'react';

import {
    HomeNewsletter
} from '../../../components';

import {
    Container,
    Avatar,
    Heading,
    Eyebrow,
    Paragraph,
    
} from './styled';

const Notfound = () => {
    return (
        <Container>
            {/* <Avatar src="/avatar.jpg" alt="Picture of XPERRIA"/> */}
            <Heading>
            Page not found!
            </Heading>
            <Paragraph>
            Back to <a href="https://xperria.com">Home</a> 
                <br/><br/>
                {/* This is where hhcc I write my long-form thoughts about development, business, and whatever else piques my interest. For my day-to-day ramblings, be sure to follow me over on <a href="https://twitter.com/kpmdev" target="_blank" rel="noopener">Twitter</a>. */}
            </Paragraph>

            <HomeNewsletter/>
        </Container>
    );
}

export default Notfound;
