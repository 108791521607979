import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import {
    Container,
    PostList,
    PostListItem,
    Date,
    Heading,
    Eyebrow,
    SubHeading,
    Paragraph,
    Preview
} from './styled';

const HomePostList = () => {

    const data = useStaticQuery(graphql`
        query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC, }) {
          edges {
            node {
              excerpt
              fields {
                slug
                readingTime {
                  text
                }
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
              }
            }
          }
        }
      }
    `)

    const posts = data.allMarkdownRemark.edges;

    return (
        <Container>
          <SubHeading>
          Peringkat Kasino Online Terbaik di Indonesia
          </SubHeading>
          <Paragraph>
          <li><a href="https://bk8.la">BK8</a>: Situs kasino langsung terbaik di Indonesia secara keseluruhan - Peringkat #1 oleh <a href="https://www.inmoji.com">SBS™ (Smart Bet System)</a></li>
          <li> Bspin - Kasino live Bitcoin terbaik di Indonesia.</li>
          <li> W88 - 100% live casino welcome bonus hingga Rp1.000.000.</li>
          </Paragraph>
            <PostList>
                {posts.map(( node, index ) => {
                    const { node : { excerpt, frontmatter : { title, date }, fields : { slug, readingTime : { text } } } } = node;
                    return (
                        <PostListItem>
                            <Link to={`${slug}`} key={index}>
                                <Date>
                                    {date} &nbsp;&middot;&nbsp; {text}
                                </Date>
                                <Eyebrow>
                                    {title}
                                </Eyebrow>
                                <Preview>
                                    {excerpt}
                                </Preview>
                           </Link>
                        </PostListItem>
                    )
                })}
            </PostList>
        </Container>
    );
}

export default HomePostList;
