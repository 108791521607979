import React from 'react';

import {
    HomeNewsletter
} from '../../../components';

import {
    Container,
    Avatar,
    Heading,
    SubHeading,
    Eyebrow,
    Paragraph
} from './styled';

const HomeHero = () => {
    return (
        <Container>
            <Avatar src="/avatar.jpg" alt="Picture of XPERRIA"/>
            <Heading>
            10 Pasaran Togel Paling Terkenal di Indonesia
            </Heading>
            <Paragraph>
            Inilah 10 pasaran togel paling terkenal di Indonesia dengan beragam keunggulan mulai dari besarnya hadiah yang fantastis hingga kelengkapan permainan yang ada:
                {/* <br/><br/> */}  
            </Paragraph>
            
            <SubHeading>
            Pasaran Togel Hongkong (HK)
            </SubHeading>
            <Paragraph>
            Selanjutnya ada Togel Hongkong atau kadang di sebut pasaran HK. Merupakan jenis pasaran togel yang telah digunakan oleh seluruh situs judi togel di Indonesia. popularitasnya sendiri melebihi pasaran lainnya karena menyediakan beragam hadiah yang besar hingga 10 ribu kali lipat.
            <br/><br/> 
            Pasaran ini pun menjadi penutup dari beberapa pasaran lainnya karena memiliki jadwal buka tutup tiap pukul 23.00. Jadi, kalian perlu begadang terlebih dahulu apabila ingin melihat live draw pasaran togel Hongkong saat tengah malam.
            <br/><br/> 
            Togel HK memiliki situs resmi di <a href="http://hongkongpools.co.uk">hongkongpools.co.uk</a>. Kalian dapat melihat live update terkait keluaran angka terbaru di situs tersebut atau jika tidak dapat mengaksesnya karena adanya pemblokiran dari pemerintah maka bisa mengunjungi beberapa situs penyedia keluaran HK yang ada di Indonesia.
            <br/><br/> 
            Togel HK juga memiliki jam buka di siang hari sekitar pukul 10.00 - 11.00 WIB. Namun, pasaran malamnya lebih terkenal mengingat ia sudah dimulai sejak tahun 1975 oleh sebuah club judi online asal Hongkong yaitu Hongkong Jockey Club.
            <br/><br/> 
            Di negara kita, pasaran Hongkong sudah dikenal sejak tahun 80-an. Lalu, di tahun 1994 pemerintah memberlakukan segala bentuk perjudian baik online maupun offline sehingga para pecinta togel yang ingin menikmati keuntungan dari totoan maka harus melalui website resminya.
            <br/><br/> 
              
             <li>Jam Buka Tutup: 23.00 WIB - (<a href="https://chessmove.org">Live Draw HK</a>)</li>
             <li>Hari Buka: Senin - Minggu</li>
             <li>Jumlah Putaran: 1 kali sehari</li>
             
            </Paragraph>

            <SubHeading>
            Pasaran Togel Sydney
            </SubHeading>
            <Paragraph>
            Togel Sydney termasuk satu dari 10 pasaran togel paling terkenal di Indonesia. Popularitasnya tidak perlu dipernyatakan lagi karena sudah menjadi andalan para pemain toto dari negara kita. Pasaran dengan sebutan togel SDY ini pun bahkan menjadi andalan para pemain togel senior di berbagai penjuru dunia.
            <br/><br/>  
            Nah, bila kalian para pemula yang bingung menentukan pasaran yang tepat, maka  togel SDY bisa jadi pilihan yang tepat untuk kalian coba. Pasalnya, pasaran ini menyediakan ragam keuntungan yang mungkin tidak akan kalian dapatkan di pasaran judi togel lain manapun.
            <br/><br/>  
            Contohnya hadiah jackpot yang besar yakni hingga 10 ribu kali lipat, permainan yang cenderung mudah dimainkan, dan aneka jenis pilihan permainan togel mulai dari 2D, 3D, hingga 4D yang dapat kalian sesuaikan sendiri nantinya.
            <br/><br/>  
            Jadwal pasaran ini hampir sama dengan pasaran lainnya yakni 1 kali sehari dengan hari buka mulai dari Senin sampai Minggu full tanpa libur. Walau hanya menyediakan satu pasaran per hari, tapi jika nantinya kalian menang maka hadiah yang di dapatkan tak jauh berbeda dengan toto Macau yang buka 5 kali sehari.
            <br/><br/>  
            
             <li>Jam Buka Tutup: 13.00 - 14.00 WIB (<a href="https://calcudokuonline.com">Live Draw Sydney</a>) </li>
             <li>Hari Buka: Senin - Minggu</li>
             <li>Jumlah Putaran: 1 kali sehari</li> 
            </Paragraph>

            <SubHeading>
             Pasaran Togel Singapura (SGP)
            </SubHeading>
            <Paragraph>
            Di urutan pertama ada pasaran togel SGP (Singapura) yang telah begitu terkenal di negara kita. Karena lokasinya yang dekat, maka banyak pecinta togel yang lebih memilih pasaran satu ini dibandingkan pasaran togel dari negara lain.
            <br/><br/>  
            Pasaran SGP menyediakan website remi yang digunakan untuk mengumumkan informasi live draw yaitu di www.singaporepools.com.sg. Tak seperti pasaran lain, SGP di miliki langsung oleh pemerintah Singapura sehingga terjamin keabsahannya.
            <br/><br/>  
            Jadwal buka togel Singapore hanya 5 kali dalam seminggu di mana akan libur di hari Jumat dan Selasa. Walau begitu, kalian tetap masih bisa mendapatkan kemenangan dari taruhan yang dilakukan di hari-hari lain.
            <br/><br/>  
            Kemudian untuk waktu buka tutupnya yaitu dilakukan pada 17:15 WIB -  17:38 WIB. Di waktu sore tersebut tentunya bisa kalian cek langsung secara cepat karena tidak harus menunggu tengah malam layaknya beberapa pasaran togel lainnya.
            <br/><br/>  
            Dari segi kemudahan taruhannya, togel SGP memang cenderung mudah ditebak. Bahkan, banyak pelanggan judi online dari Indonesia yang mendapatkan jackpot berkali kali dari pasaran satu ini sehingga menjadikannya super untung.
            <br/><br/>  
            Permainan yang ditawarkan di pasaran Singapura tidak hanya berupa togel saja. Melainkan ada toto online, togel 4D, dan Singapore Sweep. Masing-masing memiliki jadwal buka tutup yang berbeda-beda.
            <br/><br/>  
             <li>Jam Buka Tutup: 17:15 WIB - 17:38 WIB</li>
             <li>Hari Buka: libur di hari selasa</li>
             <li>Jumlah Putaran: 1 kali sehari</li>
            </Paragraph>
            
            <SubHeading>
             Pasaran Togel Macau
            </SubHeading>
            <Paragraph>
            Dibandingkan dengan togel HK, sejatinya togel Macau jauh lebih populer. Hal ini dikarenakan jadwal pasarannya yang buka 5 kali sehari  dengan pilihan permainan yang lebih banyak. Tentunya hal tersebut menjadi primadona bagi penggemar judi online tanah air yang menginginkan keuntungan besar dalam waktu singkat.
            <br/><br/>  
            PTak seperti pasaran lain, di pasaran togel Macau kalian pun bisa melakukan taruhan hanya dalam 2 angka. Jadi, misalkan terdapat 4 – 6 angka yang keluar, asalkan 2 angka yang kalian pilih tadi muncul dalam deretan angka tersebut maka kalian dinyatakan menang.
            <br/><br/>  
            Aturan yang dimiliki oleh pasaran ini pun sangat mendasar. Kalian tidak hanya memiliki kebebasan dalam menebak angka, namun juga dalam memasang angka baik di tengah, ekor, maupun di kepala. Semuanya tidak akan melanggar peraturan yang ada.
            <br/><br/>  
            Karena buka dalam lima kali sehari, maka kalian dapat menentukan sendiri pilihan permainan yang ingin dipertaruhkan. Modal yang dapat dipertaruhkan di setiap kali taruhan pun hanya sebesar Rp 100 rupiah. Tentunya sangat kecil bukan? Potensi keuntungan yang di dapatkan pun hingga Rp 10 juta.
            <br/><br/>  
  
             <li>Jam Buka Tutup: setiap pukul 13.00, 16.00, 19.00, 22.00, 24.00 WIB.</li>
             <li>Hari Buka: Senin - Minggu</li>
             <li>Jumlah Putaran: 5 kali sehari</li>
            </Paragraph>

            <SubHeading>
             Pasaran Togel Taiwan
            </SubHeading>
            <Paragraph>
            Selanjutnya ada pasaran Taiwan. Memang, popularitasnya tidak sebaik pasaran togel sebelumnya. Akan tetapi, pasaran ini tergolong sebagai pasaran yang menguntungkan dan juga mudah di tebak oleh para pecinta togel baik di prediksi menggunakan firasat, jurnal mimpi, ataupun rumus warna.
            <br/><br/>  
            Sebagai pasaran yang tergolong baru, togel Taiwan juga sangat terkenal di kalangan para pecinta judi online yang telah terbiasa bermain di pasaran Cina dan Hongkong. Hal ini karena ketiga pasaran tersebut memiliki karakteristik yang hampir sama satu sama lain.
            <br/><br/>  
            Nah, untuk melihat hasil live draw pasaran togel Taiwan, maka kalian bisa mengunjungi langsung taiwanlotto.com yang merupakan website resminya. Namun, untuk akses yang lebih mudah bisa mengunjungi website penyedia hasil keluaran togel Taiwan yang bisa kalian temui di halaman pencarian.
            <br/><br/>  
            Togel Taiwan memiliki jadwal buka tutup tiap jam 9 malam atau tepatnya yaitu pukul 20.45. Waktunya masih terbilang dini sehingga kalian tidak perlu begadang hingga tengah malam hanya untuk mengecek hasil angka keluaran terbarunya.
            <br/><br/>  
            Tak seperti toto Macau, pasaran Taiwan hanya buka 1 kali sehari. Namun, jika kalian mendapatkan jackpot di satu taruhan tersebut, maka tentunya akan mendapatkan keuntungan yang besar hingga 10 ribu kali lipat dari modal. Tertarik untuk mencobanya?
            <br/><br/> 
             <li>Jam Buka Tutup: 21.00 WIB</li>
             <li>Hari Buka: Setiap hari</li>
             <li>Jumlah Putaran: 1 kali sehari</li>
            </Paragraph>

            <SubHeading>
            Pasaran Togel China
            </SubHeading>
            <Paragraph>
            Pasaran China memang cenderung agak sulit ditebak, terutama oleh para pemula, tidak seperti halnya pasaran Sydney. Oleh karena itu, kalian perlu memahami seluk beluknya terlebih dahulu apabila berencana untuk bertaruh di pasaran togel satu ini.
            <br/><br/>
            Namun, hadiah yang didapatkan sangatlah besar karena tersedia 3 prize utama dengan hadiah hingga 10 ribu x lipat serta ada hadiah special prize yang juga tak kalah menguntungkan. Terdapat pula beberapa diskon menarik yang bisa kalian dapatkan secara cuma-cuma.
            <br/><br/>
            Pasaran togel China dikelola langsung oleh pemerintah negara Tiongkong dengan memiliki website resmi berupa chinapools.asia. Apabila kalian hendak berkunjung ke situs tersebut, pastikan untuk menggunakan VPN agar dapat mengaksesnya mengingat saat ini sedang terjadi pemblokiran internet positif.
            <br/><br/>
            Togel China juga gmenyediakan tebakan angka hingga 6 angka. Ajdi, apabila kalian bisa menebak angka tepat hingga 6 angka secara keseluruhan, maka potensi hadiah yang didapatkan pun sangatlah besar hingga ratusan juta. Tentunya, tidak akan kalian dapatkan di  beragam pasaran togel lainnya.
            <br/><br/>
            Jadwal buka togel China (CN) hanya satu kali sehari setiap pukul 15.00 sore. Setiap hari buka mulai dari Senin – Minggu tanpa hari libur satupun. Kalian bisa memanfaatkan hal tersebut untuk meraup keuntungan sebanyak-banyaknya.
            <br/><br/>
            <li>Jam Buka Tutup: 15.15 WIB - 15.30 WIB</li>
            <li>Hari Buka: Senin - Minggu </li>
            <li>Jumlah Putaran: 1 kali sehari</li>
            </Paragraph>

            <SubHeading>
            Pasaran Togel New York
            </SubHeading>
            <Paragraph>
            Dari Amerika ada beberapa pasaran yang bisa kita temui, salah satunya yaitu pasaran New York yang terkenal akan jadwal buka tutupnya yang mengawali beragam pasaran lainnya yaitu pada pukul 00.00 tengah malam atau tepatnya 23.49 – 00.01 WIB & 02-15 – 02.25 waktu Indonesia barat.
            <br/><br/>
            Tak seperti pasaran togel paling terkenal lainnya, New York (NY) memang kurang begitu terkenal oleh para pecinta judi di Indonesia. Hal ini dikarenakan situs judi togel yang mendukungnya tidak sebanyak yang lain sehingga menjadikannya kurang begitu populer.
            <br/><br/>
            Walau demikian, kredibililtasnya tidak perlu kalian ragukan lagi mengingat pasaran dengan situs resminya yakni www.newyork4d.net ini dikelola secara langsung oleh pemerintah setempat. Bagi para pemain judi togel dari negara barat pun banyak yang memilih pasaran togel satu ini.
            <br/><br/>
            Pembukaan pasaran ini yakni 2 kali sehari. Siang bernama New York Midday dan malam bernama New York Evening. Kalian dapat menentukan sendiri pasaran yang ingin kalian masuki nantinya sesuai dengan waktu taruhan kalian.
            <br/><br/>
            <li>Jam Buka Tutup: 23.49 - 00.01 WIB & 02-15 - 02.25 WIB</li>
            <li>Hari Buka: Setiap hari</li>
            <li>Jumlah Putaran: sehari dua kali </li>
            </Paragraph>

            <SubHeading>
            Pasaran Togel PCSO
            </SubHeading>
            <Paragraph>
            PCSO disebut juga pasaran Filipina. Ia menjadi salah satu pasaran terlaris dan terpopuler di Indonesia karena telah tersedia di beragam situs judi togel di Indonesia dan juga telah terdaftar resmi di Asosiasi Lotere Dunia (WLA).
            <br/><br/>
            Kalian bisa bertaruh di pasaran ini dengan nominal mulai dari Rp 100. Dengan begitu, kesempatan untuk bertaruh bisa meningkat lebih banyak serta mendapatkan keuntungan yang lebih besar.
            <br/><br/>
            Permainan yang tersedia pun sangat lengkap. Contohnya ada Shio, colok naga, colok jitu, togel 2D, togel 3D, togel 4D, kombinasi, colok macau, dan lainnya. Nantinya, kalian bisa memilih sendiri masing-masing permainan sesuai dengan keahlian togel yang kalian miliki.
            <br/><br/>
            Jadwal pembukaan dan pengeluaran live draw dari PCSO yaitu 19.50 – 20.25 WIB setiap harinya. Hampir setiap hari buka, hanya pada hari Minggu saja pasaran ini akan libur. Jadi, kalian bisa memanfaatkan waktu libur tersebut untuk beristirahat.
            <br/><br/>
            Selain itu, togel PCSO juga terkenal akan diskonnya yang besar yakni hingga di atas 60%. Bagi kalian yang ingin mendapatkan banyak keuntungan dari modal yang relatif kecil, maka pilih saja pasaran ini karena akan begitu menguntungkan, terlebih jika mendapatkan jackpot.
            <br/><br/>
            <li>Jam Buka Tutup: 19.50 - 20.25 WIB</li>
            <li>Hari Buka: Senin - Sabtu </li>
            <li>Jumlah Putaran: 1 kali sehari</li>
            </Paragraph>

            <SubHeading>
            Pasaran Togel Kentucky
            </SubHeading>
            <Paragraph>
            Di urutan selanjutnya ada pasaran Kentucky dari Amerika. Ia memiliki website resmi yang menyediakan live update result pasaran togel Kentucky di www.kylottery.com. Kalian nantinya bisa mengunjungi website tersebut apabila ingin melihat info keluaran angka terbaru setiap harinya.
            <br/><br/>
            Hampir semua jenis permainan mulai dari 2D, 3D, 4D, colok naga, hingga kombinasi semuanya tersedia di dalamnya. Silakan sesuaikan sendiri dengan permainan favorit kalian masing-masing.
            <br/><br/>
            Togel Kentucky juga memiliki jam buka dua kali dalam sehari. Jadi, kalian bisa melakukan taruhan dengan nominal lebih kecil agar bisa dibagi ke dalam dua periode waktu yang berbeda tersebut. Dengan begitu, risiko kerugian menjadi lebih kecil.
            <br/><br/>
            Tak seperti pasaran PCSO, Kentucky buka setiap hari mulai dari Senin – Minggu. Kalian bisa bertaruh pada beragam jenis angka yang dikeluarkan tiap harinya. Oleh karena itu, sudah ada banyak pelanggan yang memenangkan jackpot berkali kali dari pasaran satu ini.
            <br/><br/>
            <li>Jam Buka Tutup: 01.00 WIB dan 11.00 WIB</li>
            <li>Hari Buka: Senin - Minggu</li>
            <li>Jumlah Putaran: 2 kali sehari</li>
            </Paragraph>

            <SubHeading>
            Pasaran Togel Magnum4D
            </SubHeading>
            <Paragraph>
            Pasaran Magnum atau kadang disebut Magnum4D merupakan pasaran dari negara Malaysia. Memang, dibandingkan pasaran lain kurang terkenal tidak seperti Singapura maupun Sydney. Namun ia juga menjadi pasaran yang menjadi primadona bagi sebagian kalangan pecinta judi.
            <br/><br/>
            Salah satu hal yang menguntungkan dari pasaran togel Magnum4D yaitu kemudahan permainannya dibandingkan dengan pasaran lain. Dengan begitu, kesempatan untuk memenangkan taruhan togelnya pun akan meningkat. 
            <br/><br/>
            Hadiah yang ditawarkan pun besar yakni hingga 10 ribu kali lipat dengan total 23+ hadiah tambahan. Diskon yang ditawarkan pun sampai 65% sehingga begitu menguntungkan di kalangan pecinta togel online pemula maupun pro.
            <br/><br/>
            Sayangnya, pasaran ini tidak buka setiap hari, yakni pada Rabu, Sabtu, dan Minggu. Jadi, seminggu akan libur selama 4 kali. Jadi, bisa dibilang kurang cocok bagi mereka para maniak togel online yang selalu bertaruh setiap saatnya.
            <br/><br/>
            <li>Jam Buka Tutup: 18.10 - 18.40 WIB</li>
            <li>Hari Buka: Rabu, Sabtu, dan Minggu</li>
            <li>Jumlah Putaran: sekali sehari</li>
            </Paragraph>
            <Paragraph>
            Itu dia 10 pasaran togel paling terkenal di Indonesia yang dapat kalian pilih nantinya. Apakah tertarik untuk mencoba salah satunya?
            </Paragraph>

            {/* <HomeNewsletter/> */}
        </Container>
    );
}

export default HomeHero;
