import React from 'react';

import {
    Container,
    Copyright,
    SocialList,
    SocialListItem,
    SocialListLink
} from './styled';

const Footer = () => {
    return (
        <Container>
            <Copyright>
                &copy; 2021-{new Date().getFullYear()} &mdash; XPERRIA 
            </Copyright>
            <SocialList>
                <SocialListItem>
                    <SocialListLink 
                        href="/contact"
                        rel="noopener"
                    >
                        Hubungi Kami
                    </SocialListLink>
                </SocialListItem>
                <SocialListItem>
                    <SocialListLink 
                        href="/kebijakan-privasi"
                        rel="noopener"
                    >
                        Kebijakan Privasi
                    </SocialListLink>
                </SocialListItem>
                {/* <SocialListItem>
                    <SocialListLink 
                        href="https://linkedin.com/"
                        target="_blank"
                        rel="noopener"
                    >
                        LinkedIn
                    </SocialListLink>
                </SocialListItem> */}
            </SocialList>
        </Container>
    );
}

export default Footer;
